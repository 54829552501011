.payment-header {
  margin: 0 !important;
  border: 1px solid #BDBDBD;
  border-bottom: 0;
}

.payment-report {
  background: #F1F1F1 !important;
  border: 1px solid #BDBDBD;
  .table {
    background: white;
    margin-top: 20px;
    thead {
      background: #E91D63;
      color: white;
    }

    .cell {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .part {
    padding: 20px;
  }

  h2, h3 {
    background: transparent !important;
    font-weight: bold;
    border-bottom: 0 !important;
  }

  h2 {
    margin: 0 !important;
    padding: 20px 0 !important;
    font-weight: 100;
  }

  .total {
    background: white;
    border: 1px solid #ddd;
    padding: 0;

    .row {
      &:not(:last-child){
        border-bottom: 1px solid #ddd;
      }
      .col-title {
        border-right: 1px solid #ddd;
      }

      > div {
        padding: 10px;
      }
    }
  }

  .place-block {
    padding-bottom: 20px;
    &:not(:first-child){
      padding-top: 20px;
      border-top: 1px solid #BDBDBD;
    }
  }

  .payment-totals {
    margin: 0 -20px;
    padding: 12px 20px;
    background: #F1F1F1;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
  }
}

.report-actions {
  padding: 20px;
  margin-bottom: 10px;
  background: #FFF;
  border: 1px solid #BDBDBD;
  border-top: 0;

  .col {
    padding: 0;
  }
}

.internal_check {
  background: #efefef78;
}

.final-total {
  background: #E91D63;
  color: white;
  font-weight: bold;
}
