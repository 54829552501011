
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
     -moz-box-shadow: @shadow;
          box-shadow: @shadow;
}

.border-radius (@radius: 5px) {
    -webkit-border-radius: @radius;
       -moz-border-radius: @radius;
            border-radius: @radius;

    -webkit-background-clip: padding-box;
       -moz-background-clip: padding;
            background-clip: padding-box;
}

.border-radiuses (@topleft: 0,  @topright: 0, @bottomright: 0, @bottomleft: 0) {
    -webkit-border-bottom-right-radius: @bottomright;
     -webkit-border-bottom-left-radius: @bottomleft;
       -webkit-border-top-right-radius: @topright;
        -webkit-border-top-left-radius: @topleft;

    -moz-border-radius-bottomright: @bottomright;
     -moz-border-radius-bottomleft: @bottomleft;
       -moz-border-radius-topright: @topright;
        -moz-border-radius-topleft: @topleft;

    border-bottom-right-radius: @bottomright;
     border-bottom-left-radius: @bottomleft;
       border-top-right-radius: @topright;
        border-top-left-radius: @topleft;

    -webkit-background-clip: padding-box;
       -moz-background-clip: padding;
            background-clip: padding-box;
}

.transition(@duration:0.2s, @type:all, @ease:ease-out) {
    -webkit-transition: @type @duration @ease;
    -moz-transition: @type @duration @ease;
    -o-transition: @type @duration @ease;
    transition: @type @duration @ease;
}

.appearance(@value: none) {
    -webkit-appearance: @value;
    -moz-appearance: @value;
    appearance: @value;
}

.visibility(@value: hidden) {
    -webkit-backface-visibility: @value;
    -moz-backface-visibility: @value;
    backface-visibility: @value;
}

.transform(@value) {
    -webkit-transform: @value;
    -ms-transform: @value;
    -o-transform: @value;
    -moz-transform: @value;
    transform: @value;
}

.animation(@name) {
    animation-name: @name;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: @name;
    -webkit-animation-duration: 2000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: @name;
    -moz-animation-duration: 2000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: @name;
    -ms-animation-duration: 2000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
}

.adjusted () {
    height: 100%;
    min-height: 100%;
}

.adjusted (@offset) {
    height: calc(~"100% - " @offset);
    min-height: calc(~"100% - " @offset);
}