@import "../variables.less";
@import "../mixins.less";

#signin,
#signup,
#password,
#password_change {
    background-color: #f1f1f1;
    position: absolute;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .account-form-container {
        position: absolute;
        top: 30px;
        left: 50%;
        width: 350px;
        margin-left: -175px;

        .account-form-header {
            text-align: center;
            padding: 20px;
            color: #999;
            font-size: 12px;
            min-height: 176px;

            img {
                width: 160px;
                display: inline-block;
                margin-bottom: 20px;
            }

            p {
                margin-top: 12px;
            }
        }

        .account-form {
            position: relative;
            background-color: #fff;
            .box-shadow(0 1px 3px rgba(0, 0, 0, 0.15));
            padding: 30px 40px 30px 40px;

            #btn-signin {
                .box-shadow(0 1px 1px rgba(0, 0, 0, 0.40));
                border: none;
                padding: 8px;
            }

            .btn-wide {
                margin-top: 10px;
            }

            .input-group-addon {
                background-color: #fff;
                border: none;
                font-size: 11px;
                color: #888;       
            }

            input {
                padding-left: 10px;
            }

            .disclaimer {
                font-size: 11px;
                margin-top: 30px;
            }

            .split div {
                line-height: 40px;
            }

            #facebook,
            #google {
                width: 100%;
                color: #fff;
                text-align: left;
                position: relative;
                padding: 8px 12px 8px 50px;
                .border-radius(3px);
                margin-top: 10px;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 36px;
                    float: left;
                }
            }

            #facebook {
                background-color: #3b5998;
            }

            #google {
                background-color: #d62d20;
            }

        }

        .account-form-footer {
            padding: 10px;
            font-size: 12px;

            a {
                padding-bottom: 5px;
                border-bottom: 1px solid #999;
            }
        }
    }
}

@media (max-width: 1360px) {

  
}

@media (max-width: 991px) {

    
}


@media (max-width: 767px) {

	#signin,
	#signup,
	#password,
	#password_change {
		overflow: auto;

		.account-form-container {
			top: 0;

			.account-form-header {
				min-height : 0;

				img {
					width: 120px;
				}
			}

			.account-form {

	            #btn-signin {

				}
			}
		}
	}
}