@import "variables.less";
@import "mixins.less";

.place-preview {
    height: 200px;
    position: relative;
    margin-bottom: 25px;

    .place-img {
        background-position: bottom;
        background-size: cover;
        width: 100%;
        height: 200px;
    }

    .action {
        position: absolute;
        top: 10px;
        right: 30px;

        a {
            color: #fff;
            font-size: 25px;
        }

        a:hover {
            color: #f1f1f1;
        }
    }
}

.place-preview-add {
    width: 100%;
    .transition(.2s);
    padding: 0;
    text-align: center;
    border: 1px solid #ddd;
    .border-radius(0);

    .glyphicon {
        font-size: 48px;
        color: #888;
        padding: 75px 0;
        display: inline-block;
    }
}

.place-preview-add:hover {
    border-color: @main;

    .glyphicon {
        color: @main !important;
    }
}

#booking-header {
    padding: 0 20px !important;

    > h2 {
        margin: 0 -20px 0 !important;
        border: none !important;
    }
}

.booking {
    margin-bottom: 5px;
    padding: 0 20px 0 !important;

    .booking-title {
        .box-shadow(inset 3px 0px 0px 0px @main);
        padding: 15px 0px 8px !important;
        margin: 0;
    }

    .booking-title:hover {
        background-color: #f9f9f9;
        cursor: pointer;
    }

    h3:first-child {
        margin-top: 0 !important;
    }

    .row {
        margin: 0 -20px;
        padding: 15px 0;
    }

    .row:first-child {
        border-top: 1px solid #f1f1f1;
    }
}

.email-form {

    .content {
        margin-bottom: 10px;
    }

    .row {
        .col-md-6:nth-child(odd) {
            padding-right: 5px;
        }

        .col-md-6:nth-child(even) {
            padding-left: 5px;
        }
    }

    .email-number {
        font-weight: 700;
        margin-right: 15px;
    }
}

.drop-box.drop-documents {
    height: 150px;
    line-height: 35px;
    padding-top: 32px;
}

.attachments-btn {
    margin-left: 10px;
}

.has-attachments {
    color: @secondary;
}

.attachment-ctn {
    position: relative;

    .attachment {
        height: 150px;
        text-align: center;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        display: block;

        .glyphicon {
            margin-top: 48px;
            font-size: 30px;
            margin-bottom: 10px;
        }

    }

    .action {
        position: absolute;
        top: 8px;
        right: 23px;
        z-index: 99;

        a {
            color: #555;
            font-size: 20px;
        }

        a:hover {
            color: #333;
        }
    }
}

.payments-modal {
    overflow: hidden !important;

    .modal-dialog {
        width: 1000px;
        height: 100%;

        .modal-content {
            height: 90%;
        }

        .modal-body {
            height: calc(~'100% - 115px');
            overflow-y: scroll;
        }
    }
}

#welcome {
    h2 {
        font-size: 16px !important;
    }

    p {
        font-size: 16px;
    }
}

#notifications {
    .user > div {
        margin-bottom: 9px;
    }

    .glyphicon-user {
        margin-right: 10px;
        top: 1px;
    }
    .creator {
        color: @main;
    }

    .editor {
        color: #888;
    }

    .responsible {
        color: @secondary;
    }
}

#reservation_import {
  .place-name {
    color: @secondary
  }

  .updated {
    background: #ffeabc;
  }
}
