.mission-date {
  padding: 10px;
  background: white;
}

.mission-content {
  position: relative;
  padding: 10px;
  background: #f9f9f9;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  .row {
    padding: 10px 0;
  }
}

.mission-action {
  padding: 20px;
  background: white;
  margin-bottom: 2px;

  .btn {
    margin-bottom: 10px;
  }
}

.missions-filter {
  padding-right: 30px;
}

.missions-filters {
  max-height: 0;
  overflow: hidden;
  margin-bottom: 10px;
  transition: all .5s ease;

  .filters {
    padding: 10px;

    .form-control, input {
      background: transparent;
    }
  }

  &.show {
    max-height: 250px !important;
  }

  .filter {
    padding: 10px 0;

    .btn {
      width: 100%;
    }
  }
}

.mission-state {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}

#app-container.menu-open {
  .missions-mobile-container{
    width: ~"calc(100% - 250px)";
  }
}
