@import "variables.less";
@import "mixins.less";

@media (min-width: 992px) {
    #mobile-header {
        display: none;
    }
}

@media (max-width: 991px) {
    #mobile-header {
        background-color: #fff;
        border-bottom: 1px solid #ddd;

        #menu-toggle {

        }
    }

    #column-menu {
        .transition(.2s, left);
        left: -250px;
    }

    #column-content {
        .transition(.2s, left);
        left: 0;
        width: 100%;

        #container-content {
            padding: 10px;
            .adjusted(61px);

            form .form-footer {
                left: 0;
                width: 100%;
            }
        }
    }

    .menu-open {
        #column-menu {
            left: 0;
        }

        #column-content {
            left: 250px;
        }
    }

    #global-calendar {
        #toolbar {
            width : 100%;
            left: 0;
        }
    }
}

@media (max-width: 767px) {

    #column-menu {

        #header {
            height: auto;
            padding-left: 20px;

            img {
                width: 100px;
            }
        }
    }

    #column-content {
        #container-content {
            padding: 0;
            .adjusted(40px);

            .content-header {
                padding-top: 15px;

                .redirect {
                    font-size: 15px;
                }
            }

            form {

                .form-footer,
                .form-confirm {
                    left: 0;
                    width: 100%;
                }
            }
        }
    }
}