.pac-container {
  z-index: 2000;
}

.input-place-search-input {
  display: flex;
  align-items: center;
}

.processing-btn {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.loader {
  border: 3px solid  #7A7A7A;
  border-radius: 50%;
  border-top: 3px solid #f3f3f3;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.relative {
  position: relative;
}

.centering {
  justify-content: center;
}

.left-icon {
  position: absolute;
  top:6px;
  left:10px;

  .icon-background {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0 0 1px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: rgba(82, 82, 82, 0.7);

    .white-icon {
      color: white;
      top: -2px;
    }

    .red-icon {
      color: #FD234D;
      top: -2px;
    }
  }
}

.right-icon {
  position: absolute;
  top:6px;
  right:10px;

  .icon-background {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 1px 0 0;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: rgba(82, 82, 82, 0.7);

    .white-icon {
      color: white;
      top: -2px;
    }
  }

}

.placeholder {
  height: 155px;
  border: 1px dashed #7A7A7A;

  .placeholder-elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    gap: 12px
  }
}

.edit-place {
  background-color:white;
  margin: -21px -15px 0 -25px;

  .column {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .title {
      padding: 16px;
      border-bottom: solid 1px #E1E1E1;
      color: #525252;
      font-weight: 600;
      font-size: 30px;
    }

    .row {
      height: calc(100vh - 109px);
      display: flex;
      flex-direction: row;
    }

    .menu {
      width: 290px;
      border-right: solid 1px #E1E1E1;
      overflow-y: scroll;

      .back-button {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding-bottom: 32px;
        font-size: 16px;
        font-weight: 600;
      }

      .menu-position {
        display: flex;
        flex-direction: column;

        .menu-item-distribution {
          display: flex;
          flex-direction: row;
          align-items: baseline;

          .collapse-button {
            width: 32px;
            height: 32px;
            background-color: #F1F1F1;
            text-align: center;
            line-height: 32px;
          }

          .menu-item-title {
            padding-left: 8px;
            font-weight: 600;
            font-size: 16px;
          }
        }

        .menu-subitem-position {
          padding-left: 40px;
          margin: 12px 0 18px;

          .menu-subitem-title {
            height: 30px;
            font-weight: 600;
            font-size: 12px;
            vertical-align: middle;
            line-height: 30px;
          }
        }
      }
    }

    .form-pages {
      width: calc(100% - 290px);
      overflow-y: scroll;
      height: 100%;

      .section {
        padding-bottom: 34px;
        border-bottom: solid 1px #7A7A7A;

        .section-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;
        }

        .photos {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 8px;
          align-items: center;
          padding-top: 16px;

          .photo-size {
            object-fit: contain;
            height: 155px;
            width: 206px;
          }
          .drag-and-drop {
            border: 1px dashed #7A7A7A;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .drag-and-drop-icon {
              font-size: 24px;
              color: #CFCFCF;
            }

            .drag-and-drop-text {
              font-weight: 600;
              color: #434343;
            }
          }
        }


      }

      .section-spacing {
        padding-top: 33px;
      }

      .section-title {
        font-weight:600;
        font-size: 24px;

      }



      .module.solo-last-module {
        padding-top: 25px;
        margin-bottom: -20px;
        border: none;
      }

      .module.solo-module {
        padding: 25px 0 26px;
      }

      .module.last-module {
        padding-top: 16px;
        margin-bottom: -20px;
        border: none;
      }

      .module {
        padding: 16px 0 20px;
        border-bottom: solid 1px #E1E1E1;

        .module-distribution {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .module-title {
            font-size: 16px;
            font-weight: 600;
          }

          .module-value {
            margin-top: 8px
          }

          .module-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .subsection {
        padding: 16px 0 20px;
        border-bottom: solid 1px #E1E1E1;
      }

      .solo-subsection {
        border-bottom: solid 1px #E1E1E1;
        padding: 25px 0 26px;
      }

      .subsection-distribution {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .subsection-title {
          font-size: 16px;
          font-weight: 600;
        }

        .subsection-value {
          margin-top: 8px
        }
      }

      .btn-edit {
        display: flex;
        flex-direction: row;
        align-self: center;

        .btn-label {
          color:black;
          padding-right: 10px;
          font-weight: 600;
          font-size: 12px;
        }
      }

      // .edit-button {
      //   display: flex;
      //   flex-direction: row;
      //   align-self: center;

      //   .button-label {
      //     color:black;
      //     padding-right: 10px;
      //     font-weight: 600;
      //     font-size: 12px;
      //   }
      // }
    }
  }
}



.modal {
    text-align: center;
    padding: 0!important;

    .close-modal {
      display:flex;
      justify-content: end;
      padding-right: 46px;
    }

    .body-size {
      margin: 14px 53px 64px 53px;

      .modal-title {
        display:flex;
        justify-content: center;
        font-weight: 600;
        font-size: 20px;
        color: black;
      }

      .form-spacing {
        margin-top: 56px;

        .toggle-distribution {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .form-name {
          color: black;
          font-size: 16px;
          font-weight: 600;
        }

        .form-description {
          font-weight: 600;
          margin: 8px 0 20px;
          color: #525252;
        }

        .light-description {
          font-weight: 400;
          margin-top: 8px;
          color: #525252;
          width: 500px
        }

        .separation {
          margin-top: 26px;
        }
      }
    }

    .footer-size {
      padding: 32px 61px 32px 83px;

      .footer-distribution {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        // .btn-text {
        //   // color: #434343;
        //   font-size: 16px;
        //   font-weight: 600;
        // }
      }
    }
  }

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  // margin-right: -4px; /* Adjusts for spacing */
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 760px
}

