@import "variables.less";
@import "mixins.less";

table {
    outline: none !important;
    border-collapse: collapse !important;
    border-spacing: 0 !important;

    .remove-column {
        min-width: 45px;
    }

    .none {
        color: #b7b7b7;
        padding-top: 10px;
    }

    tr.clickable {
        cursor: pointer;
        &:hover,
        &:focus {
            background: rgba(0, 166, 153, 0.02);
        }
        &:active {
            background: rgba(0, 166, 153, 0.125);
        }
    }

    tr.selected {
        background: rgba(0, 166, 153, 0.063);
        border-left: 3px solid #00a699;
    }

    th, td {
        padding: 0;
        border-bottom: 1px solid #f1f1f1 !important;
        font-size: 12px;

        .checkbox {
            margin-top: 7px;
            margin-bottom: 0;
        }
    }

    td.ellipsis {
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    td.action {
        padding: 0 !important;

        > span {
            position: relative;
            display: inline-block;
            padding: 3px 0;
            width: 23px;
            top: 2px;

            > a:before {
                left: 0;
                top: 0;
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.1);
                z-index: 0;
                border-radius: 50%;
                opacity: 0;
                .transition(.250s);
                .visibility(hidden);
                .transform(scale3d(0, 0, 0));
            }

            > a:hover:before {
                .transform(scale3d(1, 1, 1));
                opacity: 1;
            }

            > a.done {
                color: #fff;
            }

            > a.done:before {
                background-color: @secondary;
                .transform(scale3d(1, 1, 1));
                opacity: 1;
            }
        }
    }
}

.table-accordion {
    tbody {
        tr:nth-of-type(odd) {
            cursor: pointer;
        }

        tr:nth-of-type(odd):hover,
        tr:nth-of-type(even) {
            background-color: #f9f9f9 !important;
        }
    }
}

a {
    cursor: pointer;
    text-decoration: none !important;
    color: #555;
}

a:hover,
a:focus {
    outline: none !important;
    color: #222;
}

button.btn:hover,
button.btn:focus, {
    outline: none !important;
}

a.btn,
button.btn, {
    .border-radius(2px);
    padding: 6px 12px;
    .box-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active  {
    background-color: lighten(@secondary, 5%) !important;
    border-color: @secondary !important;
}

.btn-wide {
    width: 100%;
}

a.redirect {
    color: @main;
}

.glyphicon {
    top: 2px;
}

.badge {
    margin-left: 10px;
    background-color: @main;
}

.badge.inactive {
    background-color: #444;
}

.pagination {
    margin: 10px 0 0;

    > li > a {
        .border-radius(50%);
        border: none;
        padding: 0;
        height: 25px;
        width: 25px;
        line-height: 25px;
        margin-left: 1px;
    }
}

#loading {
    position: absolute;
    left : 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 999;

    #loading-container {
        z-index: 100;
        position: absolute;
        width : 100px;
        height: 100px;
        left: 50%;
        margin-left: -50px;
        top: 50%;
        margin-top: -50px;
        font-size: 18px;
        font-weight: 700;
        color: @main;
    }
}

.modal-dialog {

    .modal-content {
        .box-shadow(none);
        .border-radius(0);

        .modal-header {
            padding: 5px 15px;
        }

        .modal-footer {
            .btn {
                .border-radius(2px);
            }
        }
    }
}

.row {
    margin: 0;
}

.loading-modal {
    padding: 0;
    
    font-size: 26px;
    color: #eee;

    .modal-dialog {
        text-align: center !important;

        .modal-content {
            text-align: center !important;
            background: transparent;
            border: none;

            .glyphicon {
                display: block;
                color: #eee;
                font-size: 50px;
                .animation(spin);
                margin-bottom: 30px;
            }
        }
    }
}

.external-link {
    color: #00a699;
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
