@import "../variables.less";
@import "../mixins.less";
@import "../flex.less";

#global-calendar {
    padding: 50px 0 0 !important;
    position: relative;
    height: calc(~'100% - 35px');
    background-color: @secondary !important;

    .form-content {
        height: 100%;
    }

    .row {
        margin: 0;
        padding: 0;
    }

    .none {
        padding: 20px 15px;
        border: 1px solid #eee;
        border-top: 0;
        color: @secondary;
    }

    #toolbar {
        position: fixed;
        background-color: #444;
        width: calc(~'100% - 250px');
        padding: 15px;
        bottom: 0;
        left: 250px;
        color: #fff;

        .form-control {
            background-color: #444;
            color: #fff;
            font-weight: bold;
        }

        > label {
            font-size: 12px;
            margin-left: 15px;
        }
    }

    #week-header {
        position: absolute;
        width: calc(~'100% - 15px');
        top: 0;
        left: 0;
        color: #fff;
        border-bottom: 1px solid #eee;

        > div {
            .flex-display();
            position: relative;

            > div {
                .flex(1);
                .flex-display();
                .align-items(center);
                .justify-content(center);
                height: 50px;
                border-right: 1px solid lighten(@secondary, 2%);
            }

            > div:first-child {
                .flex(3);
                .justify-content(flex-start);
                padding: 10px;
                max-width: 250px;
                min-width: 250px;

                a {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }

    #week {
        height: calc(~'100% - 125px');
        overflow-y: scroll;
        background-color: #fff;

        .line {
            border-bottom: 1px solid #eee;
            padding: 0;
            .flex-display();
            position: relative;

            > div {
                .flex(1);
                .flex-display();
                .align-items(center);
                .justify-content(center);
                height: 80px;
                border-right: 1px solid #eee;
            }

            > div:first-child {
                .flex(3);
                .justify-content(flex-start);
                padding: 10px;
                max-width: 250px;
                min-width: 250px;

                a {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            > div:first-child:hover > a {
                z-index: 99;
                padding: 10px 25px;
                position: relative;
                overflow: visible;
                text-overflow: none;
                background: white;
                border: 1px #ccc solid;
            }

            > div.reservation {
                position: relative;

                .pipe {
                    width: calc(~'100% + 2px');
                    display: inline-block;
                    text-align: center;
                    height: 10px;
                    background: @main;
                    margin-right: -1px;
                }

                .pipe.hovered {
                    background: lighten(@main, 20%);
                    cursor: pointer;
                }

                .check-in.hovered {
                    background-color: @checkin-light;
                    cursor: pointer;
                }

                .check-out.hovered {
                    background-color: @checkout-light;
                    cursor: pointer;
                }
            }

            > div.reservation.switch {
                .justify-content(space-between);

                .pipe.pipe-end {
                    width: 40%;
                    .border-radiuses(0, 4px, 4px, 0);
                    margin-right: -1px;
                }

                .pipe.pipe-start {
                    width: 40%;
                    .border-radiuses(4px, 0, 0, 4px);
                    margin-right: -1px;
                }
            }

            > div.reservation.start {
                .justify-content(flex-end);

                .pipe {
                    width: 40%;
                    .border-radiuses(4px, 0, 0, 4px);
                    margin-right: -1px;
                }
            }

            > div.reservation.end {
                .justify-content(flex-start);

                .pipe {
                    width: 40%;
                    .border-radiuses(0, 4px, 4px, 0);
                    margin-left: -1px;
                }
            }

            .check-in,
            .check-out {
                font-weight: 600;
                font-size: 11px;
                color: #fff;
                padding: 5px;
                width: calc(~'100% - 10px');
                position: absolute;
                text-align: center;
            }

            .check-in {
                background-color: @checkin;
                top: 5px;
                left: 5px;
            }

            .check-out {
                background-color: @secondary;
                bottom: 5px;
                left: 5px;
            }
        }
    }
}
