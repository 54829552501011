@import "variables.less";
@import "mixins.less";

/* safari fix for input button */
input, textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.form-control,
.form-control-group input {
    border: 1px solid #dce0e0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding: 0;
    box-shadow: none !important;
    resize: none;
    outline: none !important;
    .border-radius(0);
    .appearance(none);
    .transition(.3s, border);
}

.form-control:focus {
    border-color: @secondary;
}

textarea:focus {
    resize: both;
    position: relative;
    z-index: 2;
}

.form-control-group {
    position: relative;

    a {
        z-index: 999;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        padding: 8px 10px 4px;
    }

    input {
        width: 100%;
        height: 34px;
        padding: 6px 12px 6px 32px;
        line-height: 1.42857143;
    }
}

.form-group {
    position: relative;

    .tooltip-ctn {
        position: absolute;
        right: 15px;
        top: 32px;
    }
}

.input-group {

    .input-group-addon {
        border: 1px solid #eee;
        background-color: #fcfcfc;
        .border-radius(0);
    }

    .form-control {
        padding-left: 15px;
    }
}

.input-info {
    margin-left: 10px;
    cursor: help;
}

.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

    label {
        padding-left: 30px;
        position: relative;
    }

    input {
        position: absolute;
        left: 0;
        top: 0;
        width: 17px;
        height: 17px;
        margin: 4px 0 0;
        margin-left: 0 !important;
        z-index: 1;
        cursor: pointer;
        opacity: 0;
        margin-top: 0;
    }

    .input-helper:before {
        position: absolute;
        left: 0;
        top: 2px;
        width: 17px;
        height: 17px;
        border: 1px solid #ccc;
        content: "";
        .transition(250ms);
        .visibility(hidden);
    }

    .input-helper:after {
        opacity: 0;
        width: 22px;
        height: 9px;
        border-bottom: 2px solid @secondary;
        border-left: 2px solid @secondary;
        border-bottom-left-radius: 2px;
        left: -1px;
        top: 2px;
        position: absolute;
        content: "";
        .transition(250ms);
        .visibility(hidden);
        .transform(scale(0) rotate(80deg));
    }

    input:checked + .input-helper:after {
        opacity: 1;
        .transform(scale(1) rotate(-50deg));
    }

    input:checked + .input-helper:before {
        .transform(scale(0));
    }
}

.radio {

    label {
        padding-left: 30px;
        position: relative;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        border: 1px solid #dadada;
        width: 19px;
        height: 19px;
        margin-left: 0 !important;
        z-index: 1;
        cursor: pointer;
        opacity: 0;
        margin-top: 0;
    }

    .input-helper:before {
        position: absolute;
        left: -1px;
        top: 0;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        border: 1px solid #ccc;
        content: "";
        .transition(250ms);
        .visibility(hidden);
    }

    .input-helper:after {
        width: 11px;
        height: 11px;
        background: @secondary;
        top: 4px;
        left: 3px;
        position: absolute;
        content: "";
        .transition(250ms);
        .visibility(hidden);
        .transform(scale(0));
        .border-radius(50%);
    }

    input:checked + .input-helper:after {
        .transform(scale(1));
    }

}

.ng-submitted {

    .ng-invalid {
        border-bottom: 1px solid #ff4d4d;

        .form-control {
            border-bottom: 1px solid #ff4d4d;
        }
    }
}

.invalid-message {
    position: absolute;
    right: 0;
    bottom: -15px;
    text-align: right;
    color: #ff4d4d;
    font-size: 10px;
    z-index: 99;
}

.modal-body {
    .invalid-message {
        right: 20px;
        bottom: -15px;
    }
}

.modal-body-grey {
    background: #eee;

    .bloc {
        background: #fff;
        border: 1px solid #ddd;
        padding: 15px 10px 0;
        border-radius: 4px;
        margin-bottom: 10px;
    }
}

.invalid-form {
    .border-radius(3px);
    padding: 8px 10px;
    margin-bottom: 7px;
    background-color: #fff;
    border: none;
    color: #ff4d4d;
}

.btn-file {
    position: relative;
    overflow: hidden;

    input[type=file] {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
    }
}

.filelink-placeholder {
    color: #cccccc;
    line-height: 29px;
}

.filelink {
    line-height: 29px;
    max-width: 68%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* datepicker */
.dropdown-menu {
    color: @main;

    li {
        margin: 0 5px;

        table {

            .btn-default,
            .btn-default:hover,
            .btn-default:focus {
                outline: none;
                border: none;
                .box-shadow(none);
                .border-radius(2px);
            }

            button.active {
                background-color: @main !important;

                .text-info {
                    color: #fff !important;
                }
            }
        }
    }

}

.form-group-timepicker {
    padding-top: 25px;
}

.timepicker {

    td {
        border-bottom: none !important;

        a.btn {
            .box-shadow(none);
        }
    }
}

.field-error {
    color: @error;
    font-size: 11px;
    position: absolute;
    z-index: 999;
    right: 0;
    bottom: 5px;

    &.centered {
        text-align: center;
        width: 100%;
    }
}

.checkbox {
    .field-error {
        right: auto;
        left: 30px;
        bottom: -12px;
    }
}

.toggle {
    .field-error {
        bottom: -20px;
        right: 25px;
    }
}

.toggle-wide {
    .field-error {
        bottom: -18px;
        right: 0;
    }
}

.row {
    > div {
        > .field-error {
           right: 16px;
        }
    }
}

.has-error .form-control {
    border: none;
}

.number-picker {
    margin-top: 15px;

    .form-control {
        position: relative;
        font-size: 1em;
        width: calc(~'100% - 90px');
    }

    .btn {
        float: right;
        margin-left: 3px;
    }
}

.form-description {
    font-size: .8rem;
    font-style: italic;
    margin: 0 0 12px 0;
}
