.report-content {
  position: relative;
  padding: 10px;
  background: #f9f9f9;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  .row {
    padding: 10px 0;
  }
}

.report-action {
  padding: 20px;
  background: white;
  margin-bottom: 2px;

  .btn {
    margin-bottom: 10px;
  }
}

#app-container.menu-open {
  .missions-mobile-container{
    width: ~"calc(100% - 250px)";
  }
}
