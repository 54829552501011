@import "layout.less";
@import "components.less";
@import "form.less";
@import "templates.less";
@import "templates/gateway.less";
@import "templates/profile.less";
@import "templates/place.less";
@import "templates/place.form.less";
@import "templates/missions.mobile.less";
@import "templates/reports.mobile.less";
@import "templates/calendar.global.less";
@import "templates/dashboard.less";
@import "templates/payment-report.less";
@import "templates/payments.less";
@import "responsive.less";
@import "templates/message.less";

