@import "../variables.less";
@import "../mixins.less";

#dashboard {

    .widget {
        padding: 15px;
        padding-left: 50px;
        color: @secondary;
        background-color: white;

        .widget-icon {
            font-size: 42px;
            opacity: .5;
        }

        h5 {
            color: #666;
            font-weight: 300;
            margin: 5px;
        }

        p {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
        }

        p.h2 {
            font-size: 30px;
            font-weight: 400;
        }
    }

}