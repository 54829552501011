.payments-header-date {
  display: flex;
  align-items: center;

  .current-date {
    margin-right: 20px;
    text-transform: uppercase;
  }

  @media screen and (max-width: 991px) {
    padding-top: 20px;
  }
}

.payment-status {
  width: 90%;
  background: transparent;
}

.payment-status--pending {
  color: #FF9800;
}

.payment-status--transferred {
  color: #5CB85C;
}

