.message {
    height: 100%;
    color: #525252;

    .row {
        display: flex;
    }

    textarea {
        margin-right: 12px;
        width: 100%;
        padding: 10px;
        border: none;
        border-bottom: 1px #888888 solid;
    }

    .title {
        font-size: 24px;
        font-weight: 600;
    }

    .underline-text {
        color: #00B0A2;
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
    }

    .glyphicon-padding {
        padding-right: 10px;
    }

    .little-bold-text {
        font-size: 12px;
        font-weight: 600;

    }

    .guest-image {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-shrink: 0;

        .user-first-letter {
            color: white;
            font-weight: 600;
            align-self: center;
        }
    }

    .green-button {
        height: 32px;
        background-color: #00B0A2;
        //background-color: cadetblue;
        border: none;
        color: white;
        //color: #E1E1E1;
        font-size: 12px;
        font-weight: 600;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .knf_reservation_link {
        margin-bottom: 8px;
    }

    .white-button {
        max-width: 50%;
        height: 32px;
        //background-color: white;
        background-color: #E1E1E1;
        flex-grow: 1;
        //border: 2px #00B0A2 solid;
        //color:#00B0A2;
        border: 2px cadetblue solid;
        color: cadetblue;
        font-size: 12px;
        font-weight: 600;
    }

    input[type="file"] {
        display: none;
    }

    .custom-file-upload {
        cursor: pointer;
    }

    .error-message {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .glyphicon-error {
        color: #FD234D;
        font-size: 30px;
    }

    .white-glyphicon {
        color: white;
    }

    .large-icon {
        font-size: 19px;
    }

    .row {
        margin: -21px -15px 0px -25px;
        height: 100%;
        background-color: white;

        .contacts {
            height: 100%;
            margin: 0;
            background-color: #F9F9F9;
            border: none;
            border-right: 1px #E1E1E1 solid;
            width: 300px;
            flex-shrink: 0;

            .contacts-header {
                padding: 16px 8px;
                height: 115px;
            }

            .contacts-header-title {
                display: flex;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: baseline;
            }

            .search-area {
                width: 100%;
                height: 32px;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 16px;

                .search-icon {
                    padding-right: 10px;
                    height: 28px;
                    line-height: 28px;
                    border-bottom: 1px #888888 solid;
                    color: #7A7A7A;
                }

                .search-input {
                    width:100%;
                    height: 32px;
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px #888888 solid;
                }
                .search-input:focus {
                    outline: none !important;
                }
            }

            .contacts-list {
                height: calc(100vh - 185px);

                .search-placeholder {
                    padding-top: 3px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .listing {
                    height: 100%;
                    overflow-y: scroll;
                    border-bottom: 1px solid lightgray;

                    .listing-border {
                        border-top: 1px solid lightgray;
                        padding: 8px;

                        .text-position {
                            margin-top: 8px;
                            padding-bottom: 12px;
                        }
                    }

                    .guest {
                        margin-top: 12px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .image-circle {
                            margin-bottom: 32px;
                            position: relative;

                            .guest-image-absolute {
                                background-color: #888888;
                                position: absolute;
                                z-index: 1;
                            }

                            .unread-point {
                                margin-left: 22px;
                                width:10px;
                                height: 10px;
                                background-color: #FD234D;
                                z-index: 3;
                                position:absolute;
                                border-radius: 50%;
                            }
                        }

                        .guest-infos {
                            padding-left: 40px;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .guest-request {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;

                                .center {
                                    align-self: center;
                                }
                            }

                            .guest-name {
                                margin-bottom:0px;
                                font-weight: 700;
                            }
                        }
                    }


                }

            }

            .pagination-container {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .pagination-page a {
                text-align: center;
            }

            .pagination {
                margin: 0 !important;
                padding: 8px;
            }

            .pagination-next, .pagination-last {
                text-align: right;
            }
        }

        .thread {
            height: 100%;

            .thread-header {
                padding: 16px 0;
                height: 65px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .thread-interaction {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: calc(100vh - 90px);

                .discussion-area {
                    overflow-y: scroll;
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 16px;

                    .text-message {
                        margin-bottom: 24px;
                        display: flex;
                        flex-direction: column;

                        .date-banner {
                            margin-bottom: 16px;
                            align-self: center;
                            font-size: 12px;
                            font-weight: 600;
                        }

                        .discussion {
                            display: flex;
                            flex-direction: row;

                            .user-name {
                                margin-bottom: 2px;
                                color: black;
                                font-weight: 600;
                            }
                        }
                    }

                    .reservation-banner {
                        margin-top: -8px;
                        width: 100%;
                        height: 32px;
                        line-height: 32px;
                        background-color: #00B0A2;
                        text-align: center;
                        color: white;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }

            }
            .post {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 16px 0;
                // height: 115px;

                .post-icon {
                    min-width: 48px;
                    min-height: 48px;
                    background-color: #FD234D;

                    .center-icon {
                        width: 100%;
                        height: 48px;
                        line-height: 48px;
                        text-align: center;
                    }
                }

                #autoExpand:focus {
                    height: 200px;
                }
            }
        }

        .reservation {
            height: 100%;
            overflow-y: auto;
            border: none;
            border-left: 1px #E1E1E1 solid;

            .reservation-header {
                padding: 16px 0;
            }

            .details-area {
                margin: 0 -16px 0px -16px;

                .journey {
                    padding: 16px;
                    border: none;
                    border-bottom: 1px #E1E1E1 solid;

                    .place-details {
                        margin: 8px 0px;
                        color: black;
                        font-size: 20px;
                        font-weight: 600;
                    }

                    .reservation-detail {
                        margin-bottom: 8px;
                    }
                }

                .request {
                    padding: 21px 23px 30px 23px;
                    border: none;
                    border-bottom: 1px #E1E1E1 solid;
                    color: black;

                    .request-title {
                        margin-bottom: 8px;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .request-approval {
                        margin-bottom: 2px;
                        font-size: 12px;
                    }

                    .request-delay {
                        margin-bottom: 16px;
                        font-size: 12px;
                    }

                    .request-buttons {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        .button-disposition {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            gap: 8px;
                        }
                    }
                }

                .host {
                    padding: 16px;
                    font-weight: 600;

                    .host-name {
                        margin-bottom: 8px;
                        color: black;
                        font-size: 20px;
                    }

                    .host-item {
                        display: flex;
                        flex-direction: row ;
                        align-items: center;
                        margin-bottom: 8px;
                    }

                    .host-info {
                        width:100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}
