@import "../variables.less";
@import "../mixins.less";

.account-alert {
    color: @secondary;
    font-size: 15px;
    padding: 10px 0 0;
    font-weight: 700;

    span {
        margin-right: 10px;
    }
}